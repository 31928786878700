import forge from 'node-forge'

const parseRsaPublicKey = (xml: any) => {
    // Using DOMParser to parse XML and extract Modulus and Exponent
    const doc = new DOMParser().parseFromString(xml, 'text/xml')
    const modulus = doc.getElementsByTagName('Modulus')[0].textContent
    const exponent = doc.getElementsByTagName('Exponent')[0].textContent

    return { modulus, exponent }
}

const createBufferAndSetRsaPublicKey = (keyDetail: any) => {
    // Convert Modulus and Exponent from Base64 to BigInteger
    const modulus = forge.util.createBuffer(
        forge.util.decode64(keyDetail.modulus || ''),
    )
    const exponent = forge.util.createBuffer(
        forge.util.decode64(keyDetail.exponent || ''),
    )

    // Create RSA public key
    return forge.pki.setRsaPublicKey(
        new forge.jsbn.BigInteger(modulus.toHex(), 16),
        new forge.jsbn.BigInteger(exponent.toHex(), 16),
    )
}

export const encryptData = (data: string, xmlKey: any) => {
    const keyDetail = parseRsaPublicKey(xmlKey)

    const rsaKey = createBufferAndSetRsaPublicKey(keyDetail)

    // Encrypt plaintext
    const encrypted = rsaKey.encrypt(data)

    // Encode encrypted data as Base64 (or any desired format)
    return forge.util.encode64(encrypted)
}
