import StyledSearchInput from './StyledSearchInput'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useDebounce } from 'packages/core'
import { Box, Typography, styled } from '@mui/material'
import { InfoOutline } from '../../eid-icons'

const TextRoot = styled(Box)(() => ({
    whiteSpace: 'nowrap',
    marginRight: '0.6rem',
    marginLeft: '0.8rem',
}))
const NotificationRoot = styled(Box)(() => ({
    width: 'fit-content',
    height: '2.4rem',
    margin: '0.8rem 0 0.6rem 0',
    padding: '0 0 0 0.8rem',
    borderRadius: '0.5rem',
    border: 'solid 0.1rem #f0cf9e',
    backgroundColor: '#ffe6b2',
}))

const ThresholdSearchInput = ({
    sx = {},
    handleSearch,
    placeholder, 
    minCharacterLength = 3,
    hideSearchInfoMessage = false,
    value = '',
    ...rest
}) => {
    const { t } = useTranslation()

    const [searchText, setSearchText] = useState(value)
    const [previousValidLength, setPreviousValidLength] = useState(0)
    const debouncedValue = useDebounce(searchText)

    const handleInputChange = (event) => {
        const { value } = event.target
        setSearchText(value)
    }

    useEffect(() => {
        if (
            (!debouncedValue && previousValidLength >= minCharacterLength) ||
            debouncedValue.length >= minCharacterLength
        ) {
            handleSearch(debouncedValue)
            setPreviousValidLength(debouncedValue.length)
        }
    }, [debouncedValue])

    return (
        <>
            {!hideSearchInfoMessage && searchText && searchText.length < 3 && (
                <NotificationRoot type="success">
                    <Box display="flex" alignItems="center">
                        <Box flex="0.1" display="flex" alignItems="center">
                            <InfoOutline />
                        </Box>
                        <Box flex="1.1">
                            <TextRoot>
                                <Typography variant="caption">
                                    {t('Three_Character_Search_Message')}
                                </Typography>
                            </TextRoot>
                        </Box>
                    </Box>
                </NotificationRoot>
            )}

            <StyledSearchInput
                sx={sx}
                fullWidth
                type="text"
                placeholder={t(`${placeholder}`)}
                value={searchText}
                onChange={handleInputChange}
                {...rest} 
            />
        </>
    )
}

export default ThresholdSearchInput
