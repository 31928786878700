import React from 'react'
import TreeNode from './TreeNode'

const TreeChildNode = React.memo(
    ({
        item,
        handleSelect,
        value,
        highlightText,
        disableAssignableCheck,
        url,
        titleProp,
        valueProp,
    }) => {
        return (
            <TreeNode
                explorer={item}
                handleSelect={handleSelect}
                value={value}
                disableAssignableCheck={disableAssignableCheck}
                highlightText={highlightText}
                url={url}
                titleProp={titleProp}
                valueProp={valueProp}
            />
        )
    },
)

export default TreeChildNode
