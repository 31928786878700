import Radio from '@mui/material/Radio'
import { List, ListItemButton, styled, Box, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Skeleton } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { Checkbox, Tooltip } from 'packages/eid-ui'

const StyledRadioIcon = styled(Radio)({
    padding: '0px 12px 0px 0px',
})

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxHeight: '208px',
        overflow: 'auto',
        borderRadius: '5px',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            margin: '4px',
            '-webkit-border-radius': '3px',
            borderRadius: '3px',
            backgroundColor: '#f7f7f7',
        },

        '&::-webkit-scrollbar-thumb': {
            width: '3px',
            borderRadius: '5px',
            border: '2px solid transparent',
            height: '20px',
            backgroundColor: '#d8d8d8',
        },
        backgroundColor: theme?.palette?.common?.white,
        '& .MuiListItemButton-root': {
            cursor: 'pointer',
            width: '100%',
            wordBreak: 'break-word',
            paddingTop: '10px',
            fontSize: '14px',

            '&:has(.Mui-checked)': {
                fontWeight: 'bold',
                backgroundColor: 'transparent',
            },
        },
    },
}))

const tooltipStyles = {
    right: '70px',
}

const DifferentiationValueSelector = ({
    loading,
    data,
    value: selectedValueId,
    onChange,
    getOptionLabel,
    checkSelectedOption,
    readOnlyOptions,
    getTooltipText,
    allowMultiSelection = false,
}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const theme = useTheme()

    return (
        <List classes={classes}>
            {loading ? (
                <>
                    <Box margin="4px 8px">
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height={20}
                        />
                    </Box>
                    <Box margin="4px 8px">
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height={20}
                        />
                    </Box>
                </>
            ) : data.length === 0 &&
              (!readOnlyOptions || readOnlyOptions.length < 1) ? (
                <ListItemButton>{t('Common_NoDataFound')}</ListItemButton>
            ) : (
                <>
                    {readOnlyOptions &&
                        readOnlyOptions.map((o, index) => (
                            <ListItemButton key={`${o.id}${index}`}>
                                <Icon
                                    name={'Checked'}
                                    style={{ marginRight: '9px' }}
                                />
                                {getOptionLabel
                                    ? getOptionLabel(o)
                                    : o.friendlyName}
                            </ListItemButton>
                        ))}

                    {data.map((i, index) => {
                        let tooltipText = ''
                        if (getTooltipText) {
                            tooltipText = getTooltipText(i)
                        }
                        return (
                            <Tooltip
                                tooltipStyles={tooltipStyles}
                                placement="top"
                                arrow={true}
                                title={tooltipText || i.fullPath || ''}
                            >
                                <ListItemButton
                                    selected={
                                        allowMultiSelection
                                            ? Array.isArray(selectedValueId) &&
                                              selectedValueId.some(
                                                  (item) => item.id === i.id,
                                              )
                                            : checkSelectedOption
                                            ? checkSelectedOption(
                                                  i,
                                                  selectedValueId,
                                              )
                                            : i.id === selectedValueId
                                    }
                                    key={`${i.id}${index}`}
                                    onClick={() => {
                                        if (allowMultiSelection) {
                                            // Handle multi-selection
                                            const isSelected =
                                                checkSelectedOption
                                                    ? checkSelectedOption(
                                                          i,
                                                          selectedValueId,
                                                      )
                                                    : selectedValueId.includes(
                                                          i.id,
                                                      )

                                            const updatedSelection = isSelected
                                                ? selectedValueId.filter(
                                                      (item) =>
                                                          item.id !== i.id,
                                                  ) // Remove object
                                                : [...selectedValueId, i] // Add full object

                                            onChange(updatedSelection)
                                        } else {
                                            // Single selection
                                            onChange(i)
                                        }
                                    }}
                                >
                                    {!allowMultiSelection ? (
                                        <StyledRadioIcon
                                            icon={<Icon name="Radio" />}
                                            checkedIcon={
                                                <Icon
                                                    name="RadioFilled"
                                                    color={
                                                        theme?.palette?.primary
                                                            ?.main
                                                    }
                                                />
                                            }
                                            checked={
                                                checkSelectedOption
                                                    ? checkSelectedOption(
                                                          i,
                                                          selectedValueId,
                                                      )
                                                    : i.id === selectedValueId
                                            }
                                        />
                                    ) : (
                                        <Checkbox
                                            edge="start"
                                            color="primary"
                                            sx={{
                                                padding: '0 1.2rem 0 1.6rem',
                                            }}
                                            checked={
                                                Array.isArray(
                                                    selectedValueId,
                                                ) &&
                                                selectedValueId.some(
                                                    (item) => item.id === i.id,
                                                )
                                            }
                                        />
                                    )}
                                    {getOptionLabel
                                        ? getOptionLabel(i)
                                        : i.friendlyName}
                                </ListItemButton>
                            </Tooltip>
                        )
                    })}
                </>
            )}
        </List>
    )
}

export default DifferentiationValueSelector
