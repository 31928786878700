import { FC, Fragment, useEffect, useState } from 'react'
import { Box, styled, Typography, useTheme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { Loader, Tooltip, useNotification } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import {
    useAccessRequestPolicy,
    useAddionalProperties,
    useAddItemsToCart,
    useAddItemToCart,
    useCart,
} from 'hooks'
import { Icon } from 'packages/eid-icons'
import { ItemDetails } from 'components'
import { useTimeConstrainedControl } from 'components/TimeConstrainedControl'
import { IResource, IResourceType } from 'core'
import makeStyles from '@mui/styles/makeStyles'
import DynamicFormDrawerComp from './DynamicForm'

export type AddToCartProps = {
    type?: 'Request' | 'Renew'
    resource: IResource
    resourceType: IResourceType
    preValidate?: any
    preAdd: any
    postAdd: any
    secondary?: any
    isSmallScreen?: boolean
    locationId?: string
    useAccessLevelPolicy?: boolean
    showTimeConstraint?: boolean
    hideSwitch?: boolean
    fetchAdditionalProperties?: boolean
    isMultiCartItems?: boolean
    checkAvailabilityFirst?: boolean
    checkAvailabilityFunction?: any
    isCheckingAvailability?: boolean
    hideStartDate?: boolean
}

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            marginBottom: '16px',
            marginTop: '40px',
            [theme.breakpoints.up('sm')]: {
                height: '80px',
                alignItems: 'center',
                position: 'relative',
            },
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column-reverse',
                paddingTop: '16px !important',
                paddingBottom: '16px !important',
            },
        },

        timeControlsContainer: {
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                marginLeft: '40px',
                alignItems: 'center',
            },
            [theme.breakpoints.down('md')]: {
                paddingBottom: '16px',
            },
        },
    }),
)

export const AddToCart: FC<AddToCartProps> = (props) => {
    const {
        showTimeConstraint = true,
        hideSwitch = false,
        fetchAdditionalProperties = true,
        isMultiCartItems = false,
        checkAvailabilityFirst = false,
        checkAvailabilityFunction,
        isCheckingAvailability = false,
        hideStartDate = false,
    } = props
    const { t } = useTranslation()

    const classes = useStyles()

    const { showWarningMessage } = useNotification()

    const { data: cart } = useCart()
    const theme = useTheme()

    const [dynamicFormData, setDynamicFormData] = useState([])
    const [isDynamicFieldFilled, setIsDynamicFieldFilled] = useState(true)

    const isBusinessRole = props.resourceType.name === 'BusinessRoles'

    const [isAdditionalFields, setIsAdditionalFormFields] = useState(false)

    const accessRequestPolicy = useAccessRequestPolicy(
        // NOTE: This is show default time from access level policy rather tha resource
        props.useAccessLevelPolicy &&
            props?.secondary?.resourceAssignment?.requestPolicyId
            ? props?.secondary?.resourceAssignment?.requestPolicyId
            : props.resource.requestPolicyId,
        isBusinessRole ? props.resource.id : '',
        isBusinessRole && props.secondary ? props.secondary.id : '',
    )
    const selectedResource = props.resource
    const isSecondary =
        props.secondary === null ? false : fetchAdditionalProperties
    const { data: additionalFormFields } = useAddionalProperties(
        selectedResource?.id,
        selectedResource?.resourceTypeId,
        props?.secondary?.id,
        isSecondary,
    )

    useEffect(() => {
        if (additionalFormFields) {
            if (
                Object.keys(additionalFormFields).length > 0 &&
                additionalFormFields.mappingFields &&
                additionalFormFields.mappingFields.length > 0
            ) {
                checkForRequiredFields()
                setIsAdditionalFormFields(true)
            } else {
                setIsAdditionalFormFields(false)
            }
        } else {
            setIsAdditionalFormFields(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [additionalFormFields])

    const checkForRequiredFields = () => {
        try {
            let allFieldFilled = true
            additionalFormFields.mappingFields.map((item: any) => {
                if (item.required) {
                    allFieldFilled = false
                }
                return false
            })
            setIsDynamicFieldFilled(allFieldFilled)
        } catch (err: any) {}
    }

    const checkIfAllRequiredSatiesfied = (data: any) => {
        let allFieldFilled = true
        additionalFormFields.mappingFields.map((item: any) => {
            if (item.required) {
                const dataMaping = data.find(
                    (x: any) => x.name === item.fieldName,
                )
                if (!dataMaping || dataMaping.value === '') {
                    allFieldFilled = false
                }
            }
            return false
        })
        setIsDynamicFieldFilled(allFieldFilled)
    }

    const handleDataChange = (data: any) => {
        checkIfAllRequiredSatiesfied(data)
        setDynamicFormData(data)
    }

    const [addItemToCart, { isLoading }] = useAddItemToCart()
    const [addItemsToCart, { isLoading: addingMultipleLoading }] =
        useAddItemsToCart()

    const handleCheckAvailabilityFirst = () => {
        checkAvailabilityFunction([
            selectedStartDate.utc().format(),
            selectedEndDate.utc().format(),
        ]).then((res: any) =>
            res?.data?.isAvailable
                ? handleAddItemToCart()
                : showWarningMessage(t('TimeSlotNotAvailable')),
        )
    }

    const handleAddItemToCart = () => {
        const timeConstraintDetails = {
            timeConstrained,
            startDateUtc: selectedStartDate,
            endDateUtc: selectedEndDate,
        }

        {
            if (isMultiCartItems) {
                let cartItems = props
                    .preAdd(timeConstraintDetails)
                    ?.map((item: any) => {
                        return {
                            ...item,
                            additionalFields: bindDynamicFieldsData(),
                        }
                    })

                if (!cartItems?.length) return

                addItemsToCart(cartItems).then(() => props.postAdd())
            } else {
                let itemToAdd = props.preAdd(timeConstraintDetails)
                if (isAdditionalFields && dynamicFormData) {
                    itemToAdd = {
                        ...itemToAdd,
                        additionalFields: bindDynamicFieldsData(),
                    }
                }
                if (itemToAdd === undefined) return

                addItemToCart(itemToAdd).then(() => props.postAdd())
            }
        }
    }

    const bindDynamicFieldsData = () => {
        try {
            const sortedDataList = dynamicFormData.map((item: any) => {
                if (item.value) {
                    return {
                        additionalField_Name: item.name,
                        additionalField_LocaleKey: item.localeKey,
                        additionalField_Value: item.value,
                    }
                } else {
                    return null
                }
            })

            return sortedDataList.filter((x: any) => x !== null)
        } catch (err) {
            return []
        }
    }

    const itemAlreadyInCart = props.resourceType.alreadyInCart(
        cart?.cartItems,
        props.resource,
        props.secondary,
    )

    const getTooltipTitle = () => {
        let message = ''
        if (props.preValidate) {
            message = props.preValidate()
        }
        if (message === '' && itemAlreadyInCart) {
            message = t('Common_ItemAlreadyInCart')
        }
        if (message === '' && hasInvalidDates) {
            message = t('Common_InvalidDates')
        }
        if (!isDynamicFieldFilled && isAdditionalFields) {
            message = t('Common_DynamicFormFieldRequired')
        }

        return message
    }
    //Creating below new method to disable Button only, previous method will disable date control as well.
    const getDisableButtonTitle = () => {
        let message = ''
        if (
            props.resourceType?.name === 'Credentials' &&
            showTimeConstraint &&
            !selectedEndDate
        ) {
            message = t('EndDateRequired')
        }
        return message
    }
    const hasTooltipValue = getTooltipTitle() !== ''
    const {
        timeConstrained,
        selectedStartDate,
        selectedEndDate,
        hasInvalidDates,
        violatesPolicy,
        timeConstrainedControl,
    } = useTimeConstrainedControl(
        accessRequestPolicy,
        accessRequestPolicy?.isTimeConstrained ?? false,
        hasTooltipValue,
        hideSwitch,
        hideStartDate,
    )

    if (!accessRequestPolicy) return <Fragment />

    if (!cart) return <Loader />

    const renderAddToCartButton = () => {
        return (
            <Tooltip title={getTooltipTitle() || getDisableButtonTitle()}>
                <Box minWidth="167px">
                    <ItemDetails.ActionButton
                        fontColor={theme?.palette?.common?.white}
                        bgColor={theme?.palette?.primary?.main}
                        width="fit-content"
                        minWidth="100%"
                        loading={
                            isLoading ||
                            addingMultipleLoading ||
                            isCheckingAvailability
                        }
                        disabled={
                            hasTooltipValue ||
                            violatesPolicy ||
                            getDisableButtonTitle() !== ''
                        }
                        onClick={
                            checkAvailabilityFirst && checkAvailabilityFunction
                                ? handleCheckAvailabilityFirst
                                : handleAddItemToCart
                        }
                    >
                        <Box display="flex" alignItems="center">
                            <Icon
                                name="AddToCart"
                                color={theme?.palette?.common?.white}
                            />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            marginLeft="10px"
                        >
                            <Typography
                                style={{ color: theme?.palette?.common?.white }}
                            >
                                {t(
                                    props.type === 'Request'
                                        ? 'Common_AddToCart'
                                        : 'Common_Renew',
                                )}
                            </Typography>
                        </Box>
                    </ItemDetails.ActionButton>
                </Box>
            </Tooltip>
        )
    }

    if (!accessRequestPolicy.isRequestAllowed) {
        return <Fragment />
    }

    return (
        <>
            {additionalFormFields && (
                <DynamicFormDrawerComp
                    additionalFormFields={additionalFormFields}
                    handleDataChange={(data: any) => handleDataChange(data)}
                />
            )}
            <PaddedDiv className={classes.root}>
                {renderAddToCartButton()}
                <Box className={classes.timeControlsContainer}>
                    {showTimeConstraint && timeConstrainedControl}
                </Box>
            </PaddedDiv>
        </>
    )
}

AddToCart.defaultProps = {
    type: 'Request',
}
