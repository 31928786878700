import {
    preApprovedOnlyFilterColors,
    directAssignedOnlyFilterColors,
    timeConstrainedOnlyFilterColors,
    suggestedOnlyFilterColors,
} from './colors'

const filters = [
    {
        forManageAccess: true,
        forRequestAccess: true,
        name: 'ShoppingFor',
        advanceSearch: true,
        requireAccess: {
            control: 'ITShop-ShopForTargetPerson-Control',
        },
        title: {
            requestAccessTitle: 'Common_TargetPerson',
            manageAccessTitle: 'Common_ManageFor',
        },
        order: 1,
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'Account',
        title: 'Common_Account',
        requireAccess: {
            control: 'ITShop-ApplicationRolesAccounts-Control',
        },
        order: 2,
        colorCode: {
            primary: '#ff5e65',
            secondary: '#fee8f2',
        },
        type: 'autocomplete',
        label: 'Common_SearchForAccount',
        placeholder: 'Common_SelectAnAccount',
        optionConfig: {
            type: 'labelWithSubLabel',
            labelProp: 'name',
            subLabelProp: 'logonName',
        },
        url: '/api/ApplicationRoles/accounts',
        queryParams: {
            take: 25,
        },
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'account',
        queryParam: {
            name: 'AccountId',
            valueProp: 'id',
        },
        basedOn: {
            type: 'dynamicUrl',
            contextType: 'global',
            contextProp: 'targetPerson',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyPreApproved',
        title: 'Common_ShowOnlyPreApproved',
        requireAccess: {
            control: 'ITShop-PreApprovedApplicationRoles-Control',
        },
        order: 2,
        colorCode: preApprovedOnlyFilterColors,
        label: 'Common_ShowOnlyPreApproved',
        type: 'checkbox',
        contextProp: 'showPreApproved',
        trueValueIndicator: 'Common_ShowOnlyPreApproved',
        default: true,
        queryParam: {
            name: 'showPreApproved',
        },
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyDirectAssigned',
        title: 'Common_ShowDirectAssignedOnly',
        requireAccess: {
            control: 'ITShop-DirectAssignedApplicationRoles-Control',
        },
        order: 2,
        colorCode: directAssignedOnlyFilterColors,
        label: 'Common_ShowDirectAssignedOnly',
        type: 'checkbox',
        contextProp: 'showDirectAssignedOnly',
        trueValueIndicator: 'Common_ShowDirectAssignedOnly',
        default: true,
        queryParam: {
            name: 'showDirectAssignedOnly',
        },
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyTimeConstrained',
        title: 'ManageAccess_ShowTimeConstrained',
        requireAccess: {
            control: 'ITShop-TimeConstrainedApplicationRoles-Control',
        },
        order: 2,
        colorCode: timeConstrainedOnlyFilterColors,
        label: 'ManageAccess_ShowTimeConstrained',
        type: 'checkbox',
        contextProp: 'showTimeConstrained',
        trueValueIndicator: 'ManageAccess_ShowTimeConstrained',
        queryParam: {
            name: 'showTimeConstrained',
        },
    },

    {
        forRequestAccess: true,
        dynamic: true,
        name: 'SuggestApplicationRoles',
        title: 'Common_SuggestAdditionalResources',
        requireAccess: {
            control: 'ITShop-SuggestedApplicationRoles-Control',
        },
        order: 3,
        colorCode: suggestedOnlyFilterColors,
        type: 'checkbox',
        label: 'ApplicationRoles_SuggestApplicationRoles',
        contextProp: 'showSuggested',
        trueValueIndicator: 'ApplicationRoles_SuggestApplicationRoles',
        queryParam: {
            name: 'showSuggested',
        },
    },
    {
        forRequestAccess: true,
        forManageAccess: true,
        name: 'TargetSystem',
        title: 'ApplicationRoles_TargetSystem',
        requireAccess: {
            control: 'ITShop-TargetSystem-Control',
        },
        order: 4,
        colorCode: {
            primary: '#307fc1',
            secondary: 'rgba(48, 127, 193, 0.09)',
        },
        contextProp: 'accountStore',
        type: 'autocomplete',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        queryParam: {
            name: 'AccountStoreGUID',
            type: 'guid',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'Applications',
        title: 'Common_Applications',
        requireAccess: {
            control: 'ITShop-ApplicationRolesApplications-Control',
        },
        order: 5,
        colorCode: {
            primary: '#ff5e65',
            secondary: '#fee8f2',
        },
        type: 'autocomplete',
        label: 'Common_Applications',
        placeholder: 'Common_SelectApplication',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        url: '/api/ProtectedAppResources',
        queryParams: {
            take: 25,
        },
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'application',
        queryParam: {
            name: 'applicationId',
            valueProp: 'id',
        },
        basedOn: {
            type: 'required',
            contextType: 'global',
            contextProp: 'targetPerson',
            valueProp: 'id',
            queryParamName: 'targetPersonId',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'ApplicationProcesses',
        title: 'ApplicationRoles_ApplicationProcesses',
        requireAccess: {
            control: 'ITShop-ApplicationProcesses-Control',
        },
        order: 6,
        colorCode: {
            primary: '#01745f',
            secondary: '#e6f1ef',
        },
        type: 'tree',
        searchable: true,
        searchPlaceholder: 'Common_Search',
        url: '/api/applicationRoles/applicationProcesses',
        dedupingInterval: 1800000,
        contextProp: 'applicationProcess',
        selectedValueIndicatorProp: 'friendlyName',
        urlOverrideProp: 'applicationProcessId',
        queryParam: {
            name: 'applicationProcessId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'BusinessFunctions',
        title: 'Common_BusinessFunctions',
        requireAccess: {
            control: 'ITShop-ApplicationRolesBusinessFunctions-Control',
        },
        order: 7,
        colorCode: {
            primary: '#960067',
            secondary: '#f4e5ef',
        },
        type: 'checklist',
        multiselect: true,
        chipsConfig: {
            type: 'round',
            size: 'small',
        },
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        searchable: true,
        searchPlaceholder: 'Common_Search',
        url: '/api/businessFunctions/businessFunctions',
        basedOn: {
            type: 'optional',
            contextProp: 'applicationProcess',
            valueProp: 'id',
            queryParamName: 'locationId',
        },
        dedupingInterval: 1800000,
        debounceInterval: 500,
        contextProp: 'businessFunctions',
        selectedValueIndicatorProp: 'friendlyName',
        queryParam: {
            name: 'businessFunctionIds',
            valueProp: 'id',
        },
        showPagination: true,
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'TCode',
        title: 'Common_TCodes',
        requireAccess: {
            control: 'ITShop-ApplicationRolesTCode-Control',
        },
        order: 8,
        colorCode: {
            primary: '#dfba00',
            secondary: '#fdfae5',
        },
        type: 'autocomplete',
        placeholder: 'Common_TCodes',
        optionConfig: {
            type: 'labelWithSubLabel',
            labelProp: 'friendlyName',
            subLabelProp: 'description',
        },
        url: '/api/globalRights/getGlobalRights',
        queryParams: {
            take: 25,
        },
        contextProp: 'tCode',
        debounceInterval: 500,
        dedupingInterval: 60000,

        queryParam: {
            name: 'tCode',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'ReferencePerson',
        title: 'Common_ShopByReferencePerson',
        requireAccess: {
            control: 'ITShop-ApplicationRoles-ShopByReferencePerson-Control',
        },
        order: 9,
        colorCode: {
            primary: 'rgb(93, 104, 112)',
            secondary: 'rgba(93, 104, 112, 0.09)',
        },
        type: 'autocomplete',
        placeholder: 'Common_SelectPerson',
        url: '/api/people/search',
        queryParams: {
            take: 10,
        },
        label: 'ApplicationRoles_ByReferencePerson',
        optionConfig: {
            type: 'person',
            labelProp: 'friendlyName',
            imageUrlProp: 'imageUrl',
        },
        excludeOptionsConfig: {
            contextType: 'global',
            contextProp: 'targetPerson',
            valueProp: 'id',
        },
        debounceInterval: 500,
        dedupingInterval: 60000,
        contextProp: 'referencePerson',
        queryParam: {
            name: 'referencePersonId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'AdvancedSearch',
        title: 'Common_AdvancedSearch',
        requireAccess: {
            control: 'ITShop-ApplicationRolesAdvancedSearch-Control',
        },
        order: 10,
        icon: {
            name: 'AdvancedSearch',
            color: '#959598',
        },
        type: 'advancedSearch',
        forms: {
            title: 'Common_Forms',
            colorCode: {
                primary: '#2920ad',
                secondary: '#ebeaf7',
            },
            filters: [
                {
                    name: 'FriendlyName',
                    type: 'thresholdSearchText',
                    placeholder: 'Common_FriendlyName',
                    contextProp: 'advancedSearch.forms.friendlyName',
                    queryParam: {
                        name: 'ResourceFriendlyName',
                        type: 'string',
                    },
                },
                {
                    name: 'TechnicalName',
                    type: 'thresholdSearchText',
                    placeholder: 'Common_TechnicalName',
                    contextProp: 'advancedSearch.forms.technicalName',
                    queryParam: {
                        name: 'name',
                        type: 'string',
                    },
                },

                {
                    name: 'Owner',
                    type: 'autocomplete',
                    label: 'ApplicationRoles_ByOwner',
                    placeholder: 'Common_SelectPerson',
                    optionConfig: {
                        type: 'person',
                        labelProp: 'friendlyName',
                        imageUrlProp: 'imageUrl',
                    },
                    url: '/api/people/search',
                    queryParams: {
                        take: 10,
                    },
                    debounceInterval: 500,
                    dedupingInterval: 60000,
                    contextProp: 'advancedSearch.forms.owner',
                    queryParam: {
                        name: 'OwnerPersonId',
                        valueProp: 'id',
                    },
                },
                {
                    name: 'HighLevelClassification',
                    type: 'thresholdSearchText',
                    placeholder: 'ApplicationRoles_HighLevelClassification',
                    contextProp: 'advancedSearch.forms.highLevelClassification',
                    queryParam: {
                        name: 'highLevelClassification',
                        type: 'string',
                    },
                },
                {
                    name: 'Description',
                    type: 'text',
                    placeholder: 'Common_Description',
                    contextProp: 'advancedSearch.forms.description',
                    queryParam: {
                        name: 'description',
                        type: 'string',
                    },
                },
            ],
        },
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearch.tags',
        },
    },
]

const attributes = [
    //Add columnName property as sorting property for Request Access and manageAccessColumnName property for Manage Access if sorting can't be done on name property
    {
        name: 'friendlyName',
        label: 'Common_FriendlyName',
        isHeader: true,
        sortable: true,
        style: { fontWeight: 'bold', color: '#282828' },
        moreInfoIcon: true,
        styles: {
            minWidth: '250px',
        },
    },
    {
        name: 'name',
        label: 'Common_TechnicalName',
        sortable: true,
        requireAccess: {
            control: 'ITShop-ApplicationRolesNameAttribute-Control',
        },
    },
    {
        name: 'accountFriendlyName',
        label: 'Common_AccountFriendlyName',
        sortable: true,
        manageAccessOnlyAttribute: true,
        requireAccess: {
            control:
                'ITShop-ApplicationRolesAccountFriendlyNameAttribute-Control',
        },
    },
    {
        name: 'resourceSystemFriendlyName',
        label: 'ApplicationRoles_ResourceSystem',
        sortable: true,
        requireAccess: {
            control: 'ITShop-ApplicationRolesResourceSystemAttribute-Control',
        },
    },
    {
        name: 'highLevelClassification',
        label: 'ApplicationRoles_HighLevelClassification',
        sortable: true,
        requireAccess: {
            control:
                'ITShop-ApplicationRolesHighLevelClassificationAttribute-Control',
        },
        headerCellStyles: {
            width: '12%',
        },
    },
    {
        name: 'description',
        label: 'Common_Description',
        sortable: true,
    },

    {
        label: 'Common_CurrentAccessEnds',
        sortable: false,
        manageAccessOnlyAttribute: true,
        component: { name: 'EndDateManageAccess' },
    },
]

const applicationRolesConfig = {
    name: 'ApplicationRoles',
    resourceTypeName: 'ApplicationRole',
    title: 'Common_ApplicationRoles',
    route: '/applicationRoles',
    requireAccess: {
        page: 'ITShop-ApplicationRoles-Page',
    },
    order: 3,
    icon: {
        small: 'ApplicationRolesSmall',
        large: 'ApplicationRolesLarge',
    },
    dataSource: {
        type: 'MS_DS',
        url: '/api/applicationRoles',
    },
    // showTimeConstrainedFilter: true,
    // showDirectAssignedOnlyFilter: true,
    workflows: [],
    filters: filters,
    attributes: attributes,
}
export default applicationRolesConfig
