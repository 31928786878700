import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useDebounce, useSwrApiGet } from '../../core'
import { CheckboxList, Pagination, ThresholdSearchText } from '../../eid-ui'

const contains = (text, searchTerm) => {
    return text.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
}

const EidChecklist = ({
    url,
    dedupingInterval = 2000,
    debounceInterval = 300,
    shouldTriggerApiCall,
    onChange,
    value,
    queryParams,
    supportsServerSideSearch = true,
    searchable = false,
    searchPlaceholder,
    fallbackMessage,
    transformResponse,
    showPagination = false,
    pageSize = 10,
}) => {
    const queryStringParts = []
    const [searchKey, setSearchKey] = useState('')
    const [page, setPage] = useState(1)
    const debouncedValue = useDebounce(searchKey, debounceInterval)

    const handlePageChange = (_, value) => {
        setPage(value)
    }
    Object.keys(queryParams).forEach((key) => {
        if (queryParams[key] !== undefined)
            queryStringParts.push(`${key}=${queryParams[key]}`)
    })

    if (supportsServerSideSearch && debouncedValue) {
        queryStringParts.push(`searchTerm=${debouncedValue}`)
    }
    if (page > 1) {
        queryStringParts.push(`skip=${(page - 1) * pageSize}&take=${pageSize}`)
    }

    const queryString = queryStringParts.join('&')
    const urlWithQs = queryString ? `${url}?${queryString}` : url

    const [filteredData, setFilteredData] = useState([])

    const { data } = useSwrApiGet(
        shouldTriggerApiCall ? urlWithQs : undefined,
        {
            dedupingInterval,
        },
    )
    const [numberOfPages, setNumberOfPages] = useState(0)
    useEffect(() => {
        if (data) {
            setNumberOfPages(data ? Math.ceil(data.totalCount / pageSize) : 0)
            let dataToSet = data.data
            if (!supportsServerSideSearch && searchKey !== '') {
                dataToSet = dataToSet.filter(
                    (item) =>
                        contains(item.friendlyName, searchKey) ||
                        contains(item.name, searchKey),
                )
            }
            if (transformResponse) {
                setFilteredData(transformResponse(dataToSet))
            } else {
                setFilteredData(dataToSet)
            }
        }
    }, [data, searchKey])

    return (
        <>
            {searchable && (
                <Box marginBottom="15px">
                    <ThresholdSearchText
                        placeholder={searchPlaceholder}
                        value={searchKey}
                        handleChange={(value) => {
                            setPage(1)
                            setSearchKey(value)
                        }}
                    />
                </Box>
            )}
            <Box paddingX="10px">
                <CheckboxList
                    dataList={filteredData}
                    fontColor="#000000"
                    itemContainerHeight="20px"
                    handleToggle={onChange}
                    checked={value}
                    loading={!data}
                    highlightText={searchKey}
                    fallbackMessage={fallbackMessage}
                />
            </Box>
            {data && showPagination && (
                <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent="center"
                    padding="5px 11px"
                >
                    <Pagination
                        count={numberOfPages}
                        size="small"
                        siblingCount={0}
                        page={page}
                        onChange={handlePageChange}
                        variant={'rounded'}
                    />
                </Box>
            )}
        </>
    )
}

export default EidChecklist
