import { useMutation, useQuery } from 'react-query'
import { isNilOrEmpty, useAuthState, useAxios } from 'packages/core'
import useConfiguration from 'useConfiguration'
import { useAppState } from 'appContext'
import { TREE_VIEW_LEVELS } from 'utils'

export const DEFAULT_FILTERS_KEY = 'ONBOARDING_FILTERS'

export const useGuidedShopFilters = () => {
    const [{ currentUserId }] = useAuthState()
    const storageKey = `guidedShop.${currentUserId}:${window.location.origin}`

    const getGuidedShopFilters = () =>
        JSON.parse(sessionStorage.getItem(storageKey))

    const clearGuidedShopFilters = () => {
        sessionStorage.removeItem(storageKey)
    }

    return {
        getGuidedShopFilters,
        clearGuidedShopFilters,
    }
}

export const useDefaultFilters = (resourceType) => {
    const [{ currentUserId }] = useAuthState()
    // eslint-disable-next-line
    const [_, dispatchAppState] = useAppState()
    const [{ savedFilters }] = useAppState()
    const { getResourceType } = useConfiguration()

    const currentResourceType = getResourceType(resourceType)

    const callApi = useAxios()
    const { isLoading, data } = useQuery(
        [DEFAULT_FILTERS_KEY],
        () =>
            callApi({
                method: 'GET',
                url: '/api/filters',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )

    // Store data to app context for reusability
    if (data?.resourceTypeFilters && !savedFilters) {
        if (Object.keys(data?.resourceTypeFilters).length > 0) {
            dispatchAppState({
                type: 'SAVE_FILETRS',
                payload: data.resourceTypeFilters,
            })
        }
    }
    const storageKey = `guidedShop.${currentUserId}:${window.location.origin}`

    const item = JSON.parse(sessionStorage.getItem(storageKey))
    if (isNilOrEmpty(item)) {
        if (isLoading) {
            return { isLoading, data }
        } else {
            if (data.showGuidedShop) {
                return { isLoading, data }
            } else {
                if (currentResourceType) {
                    const resourceTypeFilters =
                        data.resourceTypeFilters[currentResourceType.name]

                    if (resourceTypeFilters) {
                        return {
                            data: resourceTypeFilters.filters,
                        }
                    }
                }
            }
        }
    } else {
        const resItem = {
            data: {
                selectedResource: item.resource,
                selectedResourceLocationId: item?.secondLevel
                    ? item?.secondLevel?.id
                    : item?.firstLevel?.id,
            },
            isLoading: false,
        }
        return resItem
    }
    return { isLoading: true, data: {} }
}

export const useUpdateDefaultFilters = () => {
    const callApi = useAxios()
    return useMutation(
        ({ resourceType, ...data }) =>
            callApi({
                method: 'POST',
                url: `/api/filters/resourceTypeFilter/${resourceType}`,
                data,
            }),
        // Removing this as we will reuse the filters fetched at start and update in app context when changes made
        // {
        //     onSuccess: () => {
        //         queryCache.invalidateQueries(DEFAULT_FILTERS_KEY)
        //     },
        // },
    )
}

export const useTreeFilterAPI = (
    treeUrl,
    parentId,
    rootId,
    searchTerm,
    levelsToLoad = TREE_VIEW_LEVELS,
    fetchChildren,
) => {
    const callApi = useAxios()

    let apiUrl = `${treeUrl}?levelsToLoad=${levelsToLoad}`
    if (parentId) {
        apiUrl = `${apiUrl}&parentId=${parentId}`
    }
    if (rootId) {
        apiUrl = `${apiUrl}&rootId=${rootId}`
    }
    if (searchTerm) {
        apiUrl = `${apiUrl}&search=${searchTerm}`
    }

    return useQuery(
        [
            'TREE_FILTER_WITH_SEARCH',
            treeUrl,
            searchTerm,
            rootId,
            parentId,
            levelsToLoad,
        ],
        () =>
            callApi({
                method: 'GET',
                url: apiUrl,
            }).then((data) => data.data),
        {
            enabled: Boolean(treeUrl) && Boolean(fetchChildren),
            cacheTime: 1000,
        },
    )
}
