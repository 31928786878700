import { Button } from 'components'
import { FC } from 'react'
import { PreApprpovedLoginAccess } from './LoginSessionExistingAccess'
import { PreApprovedMembershipAccess } from './MembershipBasedAccess'
import { Typography, useTheme } from '@mui/material'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { ResourceTypesNamespace, useRegistry } from 'core'

export type PreApprovedItemProps = {
    computer: any
    personResourceAssignments: any
    setShowScheduledOptions: () => void
    showScheduledOptions: boolean
    handleViewRequest: () => void
}

export const PreApprovedItems: FC<PreApprovedItemProps> = (
    props: PreApprovedItemProps,
) => {
    const {
        computer,
        personResourceAssignments,
        setShowScheduledOptions,
        showScheduledOptions,
        handleViewRequest,
    } = props
    const { t } = useTranslation()
    const theme = useTheme()

    const registry = useRegistry()

    const computersType = registry.get(ResourceTypesNamespace, 'Computers')

    return (
        <>
            {personResourceAssignments?.length > 0 && (
                <PreApprovedMembershipAccess
                    handleViewRequest={handleViewRequest}
                    personResourceAssignments={personResourceAssignments}
                    resource={computer}
                    resourceType={computersType}
                    title={t('Common_MembershipBasedExistingAccess')}
                    manageBtnUrl={'computers/manageAccess?tab=loginsession'}
                />
            )}
            {computer?.isPreApproved && (
                <>
                    <PreApprpovedLoginAccess
                        handleViewRequest={handleViewRequest}
                        resource={computer}
                        resourceType={computersType}
                        manageBtnUrl={'computers/manageAccess?tab=loginsession'}
                    />
                    {showScheduledOptions && (
                        <Button
                            rootStylesProp={{
                                width: '293px',
                                height: '48px',
                                borderRadius: '5px',
                                backgroundColor: theme?.palette?.primary?.main,
                                margin: '16px 49px 12px 36px',
                                '&:hover': {
                                    backgroundColor:
                                        theme?.palette?.primary?.main,
                                },
                            }}
                            onClick={() => setShowScheduledOptions()}
                        >
                            <Icon name="Schedule" />
                            <Typography
                                style={{
                                    paddingLeft: '12px',
                                }}
                            >
                                {t('Common_ScheduleNewLoginSession')}
                            </Typography>
                        </Button>
                    )}
                </>
            )}
        </>
    )
}
