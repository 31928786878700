import { directAssignedOnlyFilterColors } from './colors'

const filters = [
    {
        forRequestAccess: true,
        forManageAccess: true,
        name: 'ShoppingFor',
        advanceSearch: true,

        requireAccess: {
            control: 'ITShop-ShopForTargetPerson-Control',
        },
        title: {
            requestAccessTitle: 'Common_TargetPerson',
            manageAccessTitle: 'Common_ManageFor',
        },
        order: 1,
    },

    {
        forManageAccess: false,
        dynamic: true,
        name: 'ShowOnlyDirectAssigned',
        title: 'Common_ShowDirectAssignedOnly',
        requireAccess: {
            control: 'ITShop-PreApprovedApplicationRoles-Control',
        },
        order: 2,
        colorCode: directAssignedOnlyFilterColors,
        label: 'Common_ShowDirectAssignedOnly',
        type: 'checkbox',
        contextProp: 'showDirectAssignedOnly',
        trueValueIndicator: 'Common_ShowDirectAssignedOnly',
        default: true,
        queryParam: {
            name: 'showDirectAssignedOnly',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'UNCPath',
        title: 'SharedFolders_UncPath',
        requireAccess: { control: 'ITShop-SharedFoldersUncPath-Control' },
        order: 2,
        icon: {
            name: 'SharedFolders',
            color: '#959598',
        },
        colorCode: {
            primary: '#307fc1',
            secondary: 'rgba(48, 127, 193, 0.09)',
        },
        type: 'autocomplete',
        primitiveType: true,
        label: 'SharedFolders_UncPath',
        placeholder: 'SharedFolders_SelectUncPath',
        optionConfig: {
            type: 'label',
            labelProp: 'value',
        },
        url: '/api/SharedFolders/uncPaths',
        minCharactersReqForSearch: 0,
        queryParams: {
            take: 25,
        },
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'uncPath',
        queryParam: {
            name: 'uncPath',
            valueProp: 'value',
        },
        basedOn: {
            contextType: 'global',
            type: 'required',
            contextProp: 'targetPerson',
            valueProp: 'id',
            queryParamName: 'targetPersonId',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'ReferencePerson',
        title: 'Common_ShopByReferencePerson',
        requireAccess: {
            control: 'ITShop-SharedFolders-ShopByReferencePerson-Control',
        },
        order: 3,
        colorCode: {
            primary: 'rgb(93, 104, 112)',
            secondary: 'rgba(93, 104, 112, 0.09)',
        },
        type: 'autocomplete',
        placeholder: 'Common_SelectPerson',
        url: '/api/people/search',
        queryParams: {
            take: 10,
        },
        label: 'Common_ShopByReferencePerson',
        optionConfig: {
            type: 'person',
            labelProp: 'friendlyName',
            imageUrlProp: 'imageUrl',
        },
        excludeOptionsConfig: {
            contextType: 'global',
            contextProp: 'targetPerson',
            valueProp: 'id',
        },
        debounceInterval: 500,
        dedupingInterval: 60000,
        contextProp: 'referencePerson',
        queryParam: {
            name: 'referencePersonId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'AdvancedSearch',
        title: 'Common_AdvancedSearch',
        requireAccess: {
            control: 'ITShop-SharedFoldersAdvancedSearch-Control',
        },
        order: 4,
        icon: {
            name: 'AdvancedSearch',
            color: '#959598',
        },
        type: 'advancedSearch',
        forms: {
            title: 'Common_Forms',
            colorCode: {
                primary: '#2920ad',
                secondary: '#ebeaf7',
            },
            filters: [
                {
                    name: 'ShareName',
                    type: 'thresholdSearchText',
                    placeholder: 'SharedFolders_ShareName',
                    contextProp: 'advancedSearch.forms.shareName',
                    queryParam: {
                        name: 'shareName',
                        type: 'string',
                    },
                },
                {
                    name: 'Owner',
                    type: 'autocomplete',
                    label: 'SharedFolders_ByOwner',
                    placeholder: 'Common_SelectPerson',
                    optionConfig: {
                        type: 'person',
                        labelProp: 'friendlyName',
                        imageUrlProp: 'imageUrl',
                    },
                    url: '/api/people/search',
                    queryParams: {
                        take: 10,
                    },
                    debounceInterval: 500,
                    dedupingInterval: 60000,
                    contextProp: 'advancedSearch.forms.owner',
                    queryParam: {
                        name: 'OwnerPersonId',
                        valueProp: 'id',
                    },
                },
                {
                    name: 'Computer',
                    type: 'thresholdSearchText',
                    placeholder: 'SharedFolders_Computer',
                    contextProp: 'advancedSearch.forms.computer',
                    queryParam: {
                        name: 'computerFriendlyName',
                        type: 'string',
                    },
                },
                {
                    name: 'DnsHostName',
                    type: 'thresholdSearchText',
                    placeholder: 'SharedFolders_DnsHostName',
                    contextProp: 'advancedSearch.forms.dnsHostName',
                    queryParam: {
                        name: 'dnsHostName',
                        type: 'string',
                    },
                },

                {
                    name: 'Description',
                    type: 'thresholdSearchText',
                    placeholder: 'Common_Description',
                    contextProp: 'advancedSearch.forms.description',
                    queryParam: {
                        name: 'Description',
                        type: 'string',
                    },
                },
            ],
        },
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearch.tags',
        },
    },
]

const attributes = [
    //Add columnName property as sorting property for Request Access and manageAccessColumnName property for Manage Access if sorting can't be done on name property

    {
        name: 'shareName',
        label: 'SharedFolders_ShareName',
        isHeader: true,
        sortable: true,
        style: { fontWeight: 'bold', color: '#282828' },
        moreInfoIcon: true,
    },

    {
        name: 'uncPath',
        label: 'SharedFolders_UncPath',
        sortable: true,
    },

    {
        name: 'dnsHostName',
        label: 'SharedFolders_DnsHostName',
        sortable: true,
    },

    {
        name: 'description',
        label: 'Common_Description',
        sortable: true,
    },
    {
        name: 'accessLevel',
        manageAccessOnlyAttribute: true,
        label: 'Common_AccessLevel',
    },
    {
        name: 'assignee',
        label: 'Common_AssignedVia',
        manageAccessOnlyAttribute: true,
    },
    {
        label: 'Common_CurrentAccessEnds',
        sortable: false,
        manageAccessOnlyAttribute: true,
        component: { name: 'EndDateManageAccess' },
    },
]

const sharedFoldersConfig = {
    name: 'SharedFolders',
    resourceTypeName: 'SharedFolder',
    title: 'Common_SharedFolders',
    route: '/sharedFolders',
    requireAccess: {
        page: 'ITShop-SharedFolders-Page',
    },
    order: 8,
    icon: {
        small: 'SharedFoldersSmall',
        large: 'SharedFoldersLarge',
    },

    dataSource: {
        type: 'MS_DS',
        url: '/api/sharedFolders',
    },
    showTimeConstrainedFilter: false,
    showDirectAssignedOnlyFilter: true,
    workflows: [],
    filters: filters,

    attributes: attributes,
}

export default sharedFoldersConfig
