import { Box, Typography, styled } from '@mui/material'
import { FunctionsList } from 'components'
import { useApplicationLocalFunctionsByPermissionLevel } from 'hooks'
import { useDebounce, useIsSmallScreen } from 'packages/core'
import { Pagination, ThresholdSearchInput } from 'packages/eid-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const PaddedDiv = styled('div')({
    padding: '0 31px',
})

const take = 10

const LocalSensitiveFunctions = ({ rbacRole, protectSubcomponent = '' }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    const [page, setPage] = useState(1)
    const [perPageItems, setPerPageItems] = useState(take)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }
    const handlePageSelection = (value) => {
        setPage(value)
    }
    const handleItemsPerPageChange = (value) => {
        setPage(1)
        setPerPageItems(value)
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const handleSearchChange = (event) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const { data } = useApplicationLocalFunctionsByPermissionLevel(
        rbacRole?.id,
        true,
        (page - 1) * perPageItems,
        perPageItems,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
    )

    const localFunctions = data ? data.data : undefined

    const localFunctionsLoading = !Boolean(data)

    const numberOfPages = data ? Math.ceil(data.totalCount / perPageItems) : 0

    return (
        <>
            {localFunctions && localFunctions.length > 0 && (
                <>
                    <PaddedDiv
                        data-protectedsubcomponent={protectSubcomponent}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '16px 31px',
                        }}
                    >
                        <Box width="50%">
                            <Typography
                                style={{
                                    fontSize: '12px',
                                    lineHeight: 1.33,
                                    color: '#9b9b9b',
                                    wordBreak: 'break-word',
                                    textTransform: 'uppercase',
                                    minWidth: '80px',
                                }}
                            >
                                {t('Common_LocalFunctionalAccess')}
                            </Typography>
                        </Box>

                        <Box
                            width={!isSmallScreen ? '308px' : '50%'}
                            minWidth="130px"
                        >
                            <ThresholdSearchInput
                                placeholder={'Common_Search'}
                                handleSearch={handleSearchChange}
                            />
                        </Box>
                    </PaddedDiv>
                    <Box overflow="auto">
                        <FunctionsList
                            data={localFunctions}
                            loading={localFunctionsLoading}
                        />

                        {!localFunctionsLoading && (
                            <Box
                                padding={!isSmallScreen ? '20px 32px' : '16px'}
                                display="flex"
                                alignItems="center"
                                justifyContent={
                                    !isSmallScreen ? 'flex-start' : 'center'
                                }
                            >
                                <Pagination
                                    count={numberOfPages}
                                    size={!isSmallScreen ? 'large' : 'small'}
                                    page={page}
                                    onChange={handlePageChange}
                                    totalCount={data?.totalCount}
                                    onPageSelection={handlePageSelection}
                                    itemsPerPage={perPageItems}
                                    onItemsPerPageChange={
                                        handleItemsPerPageChange
                                    }
                                    showPageSelection={true}
                                    showItemsPerPageSelection
                                    showCountStat
                                />
                            </Box>
                        )}
                    </Box>
                </>
            )}
        </>
    )
}
export default LocalSensitiveFunctions
