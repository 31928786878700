import React, { useState } from 'react'
import { InputBase, InputLabel, FormControl } from '@mui/material'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'

const StyledInput = styled(InputBase)(({ theme }) => ({
    width: '100% !important',
    'label + &': {
        marginTop: theme.spacing(3),
    },

    input: {
        borderRadius: '0.4rem',
        position: 'relative',
        backgroundColor: '#ffffff !important',
        border: 'solid 0.1rem #ebebed',
        fontSize: '1.6rem',
        height: '1.8rem',
        padding: '1rem 1.2rem',
        boxShadow: '0 0.4rem 0.6rem -0.2rem rgba(0, 0, 0, 0.1)',
        transition:
            'border 0.15s ease-in-out 0s, background 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
        '&:focus': {
            boxShadow: ` 0 0.2rem 0.4rem 0 ${theme.palette.primary.light}`,
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.common.white,
        },
        '&:-ms-input-placeholder': {
            opacity: `${1} !important`,
            color: '#b4b4b4 !important',
        },
    },
    focused: {
        backgroundColor: theme.palette.common.white,
    },
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    '&.validationError': {
        '& input': {
            border: 'solid 1px red !important',
        },
        '& label': {
            color: 'red !important',
        },
    },
}))

const StyledInputLabel = styled(InputLabel)({
    textTransform: 'uppercase',
    color: '#b4b4b4',
    paddingLeft: '1.6rem',
})

const TextInput = ({
    label,
    placeholder,
    value,
    handleChange,
    validationError = null,
    ...rest
}) => {
    const [showErrors, setShowErrors] = useState(false)
    return (
        <StyledFormControl
            variant="standard"
            className={classNames({
                validationError: showErrors && validationError,
            })}
        >
            {label && (
                <StyledInputLabel
                    shrink
                    fontSize="1.6rem"
                    style={{ fontSize: '1.6rem' }}
                >
                    {label}
                </StyledInputLabel>
            )}
            <StyledInput
                placeholder={placeholder}
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={() => {
                    if (!value) {
                        setShowErrors(true)
                    } else {
                        setShowErrors(false)
                    }
                }}
                {...rest}
            />
        </StyledFormControl>
    )
}

export default TextInput
