import { ThresholdSearchText } from 'packages/eid-ui'

const ThresholdSearchFilter = ({ value, onChange, placeholder, ...rest }) => {

    return (
        <ThresholdSearchText
            {...rest}
            value={value}
            handleChange={onChange}
            placeholder={placeholder}
        />
    )
}

export default ThresholdSearchFilter
