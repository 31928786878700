import { isNilOrEmpty } from 'packages/core'
import AutocompleteFilter from './AutocompleteFilter'
import { SelectFilter } from './SelectFilter'
import TextualFilter from './TextualFilter'
import ThresholdSearchFilter from './ThresholdSearchFilter'
import CheckboxFilter from './CheckboxFilter'
import NullableBooleanFilter from './NullableBooleanFilter'
import ChecklistFilter from './ChecklistFilter'
import TreeFilter from './TreeFilter'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useTranslation } from 'react-i18next'
import { NativeDatePicker } from 'packages/eid-ui/DateTimePickers'
import moment from 'moment'
import { Box, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TextField } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useEffect, useState } from 'react'
import { useAppState } from 'appContext'
import TreeViewWithSearch from './TreeViewWithSearch'

const useStyles = makeStyles((theme) => ({
    label: {
        color: '#b4b4b4',
        fontSize: '9px',
        textTransform: 'uppercase',
        width: '30px',
        position: 'absolute',
        left: '8px',
        top: '4px',
        zIndex: 50,
    },
    dueDateInputContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
            '& input': {
                cursor: 'pointer',
                borderColor: theme?.palette?.primary?.main,
            },
        },
        '& fieldset': {
            borderColor: '#ebebed',
        },
        '& input': {
            fontSize: '16px',
        },
    },
    errorMessage: {
        color: 'red',
    },
}))

const defaultValues = {
    text: '',
    checkbox: false,
    autocomplete: null,
    tree: null,
    checklist: [],
    select: '',
    nullableBoolean: null,
}

const GenericFilter = ({
    type,
    title,
    label,
    colorCode,
    contextProp,
    value,
    placeholder,
    searchPlaceholder,
    onChange,
    resourceType,
    ...rest
}) => {
    const { t } = useTranslation()
    const [{ urlFilters }] = useAppState()

    const [errorMerssage, setErrorMessage] = useState('')

    const classes = useStyles()
    const theme = useTheme()

    const overrideValue = urlFilters[rest?.urlOverrideProp]

    const [state, dispatch] = useResourceTypeContext()
    let initialValue = state[contextProp] ?? undefined

    const contextValue = state[contextProp] ?? defaultValues[type]

    useEffect(() => {
        if (state[contextProp]) {
            setErrorMessage('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state[contextProp]])

    const dispatchContextChange = (value) => {
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: contextProp,
                value: value === 'null' ? null : value,
            },
        })
    }
    const resolvedValue = value ?? contextValue
    const resolvedOnChange = onChange ?? dispatchContextChange

    if (isNilOrEmpty(type)) return <></>

    const checkIfValidDate = (selectedDate) => {
        if (contextProp === 'advancedSearch.forms.StartDateAfter') {
            if (state['advancedSearch.forms.EndDateBefore']) {
                if (
                    moment(selectedDate).isAfter(
                        state['advancedSearch.forms.EndDateBefore'],
                    )
                ) {
                    setErrorMessage(t('Common_InvalidDates'))
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        } else if (contextProp === 'advancedSearch.forms.EndDateBefore') {
            if (state['advancedSearch.forms.StartDateAfter']) {
                if (
                    moment(selectedDate).isBefore(
                        state['advancedSearch.forms.StartDateAfter'],
                    )
                ) {
                    setErrorMessage(t('Common_InvalidDates'))
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        } else {
            return true
        }
    }
    switch (type) {
        case 'text':
            return (
                <TextualFilter
                    value={resolvedValue}
                    onChange={resolvedOnChange}
                    placeholder={t(placeholder)}
                    {...rest}
                />
            )
        case 'thresholdSearchText':
            return (
                <ThresholdSearchFilter
                    value={resolvedValue}
                    onChange={resolvedOnChange}
                    placeholder={t(placeholder)}
                    {...rest}
                />
            )
        case 'checkbox':
            return (
                <CheckboxFilter
                    value={resolvedValue}
                    onChange={resolvedOnChange}
                    label={t(label)}
                    {...rest}
                />
            )
        case 'nullableBoolean':
            return (
                <NullableBooleanFilter
                    value={resolvedValue}
                    onChange={resolvedOnChange}
                    label={t(label)}
                    {...rest}
                />
            )
        case 'autocomplete':
            return (
                <AutocompleteFilter
                    value={resolvedValue}
                    onChange={resolvedOnChange}
                    placeholder={t(placeholder)}
                    label={t(label)}
                    {...rest}
                />
            )
        case 'select':
            return (
                <SelectFilter
                    value={resolvedValue}
                    onChange={resolvedOnChange}
                    placeholder={t(placeholder)}
                    label={t(label)}
                    {...rest}
                />
            )
        case 'tree':
            return (
                <TreeFilter
                    initialValue={initialValue}
                    value={resolvedValue}
                    onChange={resolvedOnChange}
                    searchPlaceholder={t(searchPlaceholder)}
                    parentId={overrideValue ? overrideValue : null}
                    {...rest}
                />
            )
        case 'checklist':
            return (
                <ChecklistFilter
                    value={resolvedValue}
                    onChange={resolvedOnChange}
                    searchPlaceholder={t(searchPlaceholder)}
                    {...rest}
                />
            )
        case 'date':
            return (
                <>
                    <NativeDatePicker
                        maxDate={moment(new Date()).format('YYYY-MM-DD')}
                        value={
                            resolvedValue
                                ? moment(resolvedValue).format('YYYY-MM-DD')
                                : ''
                        }
                        handleChange={(e) => {
                            if (isNilOrEmpty(e)) {
                                resolvedOnChange(null)
                            } else {
                                const date = moment(e)
                                const dateToSet = date
                                    .clone()
                                    .endOf('day')
                                    .set('milliseconds', 0)
                                    .utc()
                                if (
                                    checkIfValidDate(
                                        moment(dateToSet).format('L'),
                                    )
                                ) {
                                    setErrorMessage('')
                                    resolvedOnChange(
                                        moment(dateToSet).format('L'),
                                    )
                                }
                            }
                        }}
                    >
                        <Box className={classes.dueDateInputContainer}>
                            {isNilOrEmpty(resolvedValue) && (
                                <Box component="p" className={classes.label}>
                                    {label}
                                </Box>
                            )}
                            <TextField
                                type="text"
                                margin="none"
                                readOnly
                                value={
                                    resolvedValue
                                        ? moment(resolvedValue)
                                              .local()
                                              .format('L')
                                        : ''
                                }
                                style={{
                                    backgroundColor:
                                        theme?.palette?.background?.paper,
                                    width: '100%',
                                    borderColor: 'solid 1px #ebebed;',
                                }}
                                placeholder={t(placeholder)}
                            />
                            <Box position="absolute" right="8px" top="16px">
                                <Icon
                                    name="Tasks"
                                    width={19}
                                    height={20}
                                    color="#959598"
                                />
                            </Box>
                        </Box>
                    </NativeDatePicker>
                    <Box className={classes.errorMessage}>{errorMerssage}</Box>
                </>
            )
        case 'treewithsearch':
            return (
                <TreeViewWithSearch
                    value={resolvedValue}
                    onChange={resolvedOnChange}
                    searchPlaceHolder={t(searchPlaceholder)}
                    {...rest}
                />
            )
        default:
            return <></>
    }
}

export default GenericFilter
